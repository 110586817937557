/*
 * @Author: assad
 * @Date:   2022-01-20 17:04:37
 * @Last Modified by:   assad
 * @Last Modified time: 2022-02-11 16:32:13
 */


function isEmpty(data) {
    if (typeof(data) === 'object') {
        if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
            return true;
        } else if (!data) {
            return true;
        }
        return false;
    } else if (typeof(data) === 'string') {
        if (!data.trim()) {
            return true;
        }
        return false;
    } else if (typeof(data) === 'undefined') {
        return true;
    } else {
        return false;
    }
}

function itemInArray(arr, item) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] == item) {
            return true;
        }
    }
    return false;
}

function removeArrayItem(arr, item) {
    let newArr = [];
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] != item) {
            newArr.push(arr[i])
        }
    }
    return newArr;
}

const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return `${[year, month, day].map(formatNumber).join('/')} ${[hour, minute, second].map(formatNumber).join(':')}`
}

const formatDate = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return `${[year, month, day].map(formatNumber).join('-')}`
}

module.exports = {
    formatDate,
    formatTime,
    itemInArray,
    removeArrayItem,
    isEmpty
}