<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/course/img-top.png" alt="" />
    </div>
    <div class="course-box">
      <div class="course">
        <div class="course-nav">
          <div :class="{ 'course-nav-all': true, tabActive: tabActive == 0 }" @click="tabChange(0)"><span>全部</span></div>
          <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }" v-for="cateDataItem in cateData" :key="cateDataItem.cateId" @click="tabChange(cateDataItem.cateId)">{{ cateDataItem.cateName }}</div>
          <!-- <div class="course-nav-list">公共课</div> -->
        </div>
        <!-- 课程主体部分 -->
        <div class="learnCourse">
          <div class="learnCourse-list hoverLearnCourse hover-item hover-item-bigImg" v-for="cateNavDataItem in cataDataListActive" :key="cateNavDataItem.id" @click="toClasses(cateNavDataItem.url)">
            <div class="learnCourse-list-info">
              <div class="learnCourse-list-info-img">
                <img v-bind:src="cateNavDataItem.courseCoverImage" />
              </div>
              <div class="learnCourse-list-info-title hover-item-title">{{ cateNavDataItem.courseTitle }}</div>
              <div class="learnCourse-list-info-hot">
                <img src="../assets/home/leftFour-icon.png" alt="" />
                <span class="learnCourse-list-info-hot">{{ cateNavDataItem.courseView }}</span>
              </div>
            </div>
          </div>
          <div class="no-content" v-if="!cataDataList.length">
            <img src="../assets/home/noContent.png" alt="" />
          </div>
        </div>
        <!-- 分页 -->
        <el-pagination :page-size="9" background layout="prev, pager, next" :total="total" pager-count:7 @current-change="currentChange" :current-page="currentPage"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Container } from 'element-ui'
import { post, get,getXlt } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  name: 'course',
  data() {
    return {
      cateData: [],
      cataDataList: [],
      tabActive: 0,
      total: 0,
      currentPage: 1, // 当前页码
    }
  },
  methods: {
    // 分页
    currentChange(val) {
      this.currentPage = val
      window.scrollTo(0, 0)
      // this.getClassList()
    },
    // 请求课程学习栏目
     getCourseNav() {
      getXlt('/course/cates')
        .then((res) => {
          this.cateData = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //课程列表
     getCourseList(cateId = 0) {
      getXlt('/course/lists', { cateId: cateId })
        .then((res) => {
          this.cataDataList = res.data.data
          this.total = res.data.data.length
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tabChange(cateId) {
      this.getCourseList(cateId)
      this.tabActive = cateId
      this.currentPage = 1
    },
    tabFilter(list) {
      if (this.tabActive == 0) return list
      return list.filter((item) => {
        return item.cateId == this.tabActive
      })
    },

    toClasses(e) {
      window.open(e, '_blank')
    },
  },
  mounted() {
    this.getCourseNav()
    this.getCourseList()
    let siteConfig = getItem('centerConfig')
    document.title = '课程学习 - ' + siteConfig.centerName
  },
  computed: {
    cataDataListActive() {
      let endIndex = this.currentPage * 9
      return this.cataDataList.slice(endIndex - 9, endIndex)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 主体部分
.no-content {
  margin: 0 auto;
}
.topImg > img {
  width: 100%;
  display: block; //解决图片下1像素bug
}
.course-box {
  background: #eff2fa;
}
.course-nav {
  padding-top: 40px;
  padding-bottom: 56px;
  display: flex;
}
.course {
  width: 1080px;
  margin: 0 auto;
}
.course-nav-all {
  width: 64px;
  height: 22px;
  border-radius: 14px;
  cursor: pointer;
}
.course-nav-all > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 14px;
}
.course-nav-list {
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  text-align: center;
  margin: 0 12px;
}
.course-nav-list:hover {
  color: #4676f8;
}
.tabActive {
  color: #4676f8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676f8;
}
.learnCourse {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 144px;
}
.learnCourse-list {
  width: 338px;
  height: 273px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin-right: 33px;
  position: relative;
  cursor: pointer;
}
.learnCourse-list:nth-child(3n) {
  margin-right: 0;
}
.hoverLearnCourse:hover {
  width: 338px;
  height: 273px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.hover-item:hover .hover-item-title {
  color: #4676f8;
}
.hover-item-bigImg:hover {
  transform: scale(1.02);
}
.learnCourse-list-info {
  width: 306px;
  height: 240px;
}
.learnCourse-list-info-img > img {
  width: 306px;
  height: 172px;
  background: #d8d8d8;
  border-radius: 6px;
}
.learnCourse-list-info-title {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  padding-top: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.learnCourse-list-info-hot {
  padding-top: 12px;
  font-size: 12px;
  color: #9b9da2;
}
.learnCourse-list-info-hot > img {
  width: 16px;
  height: 18px;
  padding-right: 3px;
  vertical-align: middle;
}
// 分页
.el-pagination {
  text-align: center;
  padding: 0px 0px 150px;
  margin: auto;
}
</style>
