<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/paper/img-top.png" alt="" />
    </div>
    <div class="paper-box">
      <div class="paper">
        <div class="paper-list hoverPaperList hover-item"  @click="toNewsDetial(artListItem.id)"
        v-for="artListItem in artList" :key="artListItem.id">
          <div class="paper-listInfo">
            <div class="paper-left-img">
              <img v-bind:src="artListItem.imgUrl"/>
            </div>
            <div class="paper-right-text">
              <div>
                <div class="paper-list-title hover-item-title">{{ artListItem. title }}</div>
                <div class="paper-list-text">
                  {{ artListItem. summary }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-content" v-if="!artList.length">
          <img src="../assets/home/noContent.png" alt="">
        </div>
        <!-- 分页 -->
        <el-pagination
          :page-size="10"
          layout="prev, pager, next"
          :total="total"
          @current-change="currentChange"
          :current-page="currentPage"
          >
      </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from "../utils/xajax";
import { getItem } from "../utils/storage";
export default {
  name: "HelloWorld",
  data() {
    return {
      total: null,
      small: true,
      currentPage: 1, // 当前页码
      artList: [],
    };
  },
  methods: {
    currentChange(val) {
      this.currentPage = val
      this.getNewsList()
    },
    // 请求文章列表
    async getNewsList() {
      let cateName = this.$route.name;
      get("/article/lists", { cateName: cateName, page: this.currentPage})
        .then((res) => {
          this.artList = res.data.artList;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 跳转文章详情页
    toNewsDetial( artId ) {
      this.$router.push({path:`/newsDetial/${artId}`})
    },
  },
  
  mounted() {
    this.getNewsList();
    let siteConfig = getItem('centerConfig')
    document.title = '心理美文 - ' + siteConfig.centerName;
  },
   updated() { 
    window.scroll(0, 0); 
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.active-text {
  color: blue;
}
.box {
  background: #ffffff;
}
// 主体部分
.topImg > img {
  width: 100%;
  display: block; //解决图片下1像素bug
}
.paper-box {
  background: #eff2fa;
  padding-bottom: 100px;
  min-height: 500px;
}
// 分页样式
.el-pagination {
  padding-left: 70%;
  padding-bottom: 20px;
  background-color: #fff !important;
}

// .paper-box {
//   background: #eff2fa;
// }
.paper {
  width: 1080px;
  // height: 2870px;
  background: #ffffff;
  margin: 0 auto;
  padding-top: 60px;
}
.paper-list {
  width: 920px;
  height: 212px;
  background: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  cursor: pointer;
}

.paper-list:last-child {
  margin-bottom: 116px;
}
.hoverPaperList:hover {
  width: 920px;
  height: 212px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 12px;
}
.paper-listInfo {
  width: 888px;
  height: 180px;
  border-radius: 12px;
  display: flex;
}
.paper-left-img >img{
  width: 320px;
  height: 180px;
  border-radius: 12px;
}

// 文本居中图片中间显示
.paper-right-text {
  width: 536px;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.paper-right-text div {
  width: 100%;
}

.paper-list-title {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
}
.paper-list-text {
  font-size: 14px;
  color: #9b9da2;
  padding-top: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}
.hover-item:hover .hover-item-title {
  color: #4676f8;
}
.no-content{
  text-align: center;
}
</style>
