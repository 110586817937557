<template>
    <div class="box" @click="toHomeIndex()">
        <img src="../assets/home/err404.png" alt="">
    </div>
</template>
<script>
export default {
  name: "HelloWorld",
  data(){
    return {
      
    }
  },
  methods:{
      toHomeIndex(){
        //   this.$router.push("/index"); //跳到首页页面
          this.$router.go(-1); 
      }
  }
};

</script>
<style scoped>
.box>img{
    height:96vh;  
    width: 100vw;
}
</style>