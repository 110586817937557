<template>
  <div class="box">
    <!-- 面包屑 -->
    <div class="indexNews-nav">
      您的位置 :
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item><a href="">个人咨询</a></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="indexNom-box">
      <div class="indexNom-title">{{ pageData.pageName }}</div>

      <div class="indexNom-text">
        <div class="indexNom-text-info">
          <p v-html="pageData.pageBody"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      pageData: {},
    }
  },
  methods: {
    // 个人咨询
    indexNomInfo() {
      let pageName = this.$route.query.pageName
      get('/index/pageinfo', { pageName: pageName })
        .then((res) => {
          this.pageData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  computed: {},
  mounted() {
    this.indexNomInfo()
  },
  created() {},
}
</script>
<style lang="less" scoped>
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.box {
  background: #eff2fa;
  padding-bottom: 100px;
}
// 面包屑
.indexNews-nav {
  width: 1080px;
  line-height: 60px;
  display: flex;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  color: #6f7174;
}
.el-breadcrumb {
  line-height: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #6f7174;
}
.indexNom-box {
  width: 1080px;
  margin: 0 auto;
  background-color: white;
  height: 466px;
  padding-top: 60px;
}

.indexNom-title {
  width: 72px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3d41;
  margin: 0 auto;
}
.indexNom-text {
  margin: 0 auto;
  margin-top: 40px;
  width: 920px;
}
.indexNom-text-info > p {
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  text-indent: 2rem;
}

.indexNom-text-info > p > strong > img {
  margin: 0 auto;
  max-width: 80%;
  width: 800px;
  height: 450px;
}
</style>
<style>
.indexNom-text-info > p > p > img {
  margin: 0 auto;
  max-width: 92%;
}
</style>