<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/activityCenter/img-top.png" alt="" />
    </div>
    <div class="activityCenter-box">
      <div class="activityCenter">
        <div class="activityCenter-list hoverActivityCenterList hover-item" @click="toNewsDetial(artListItem.id)" v-for="artListItem in artList" :key="artListItem.id">
          <div class="activityCenter-listInfo">
            <div class="activityCenter-left-img">
              <img v-bind:src="artListItem.imgUrl" />
            </div>
            <div class="activityCenter-right-text">
              <div>
                <div class="activityCenter-list-title hover-item-title">{{ artListItem.title }}</div>
                <div class="activityCenter-list-text">
                  {{ artListItem.summary }}
                </div>
                <div class="activityCenter-time">{{ artListItem.createdAt }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-content" v-if="!artList.length">
          <img src="../assets/home/noContent.png" alt="" />
        </div>
        <!-- 分页 -->
        <el-pagination :page-size="10" layout="prev, pager, next" :total="total" :pager-count="7" v-if="artList.length != 0" @current-change="currentChange" :current-page="currentPage"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
import { getItem } from '../utils/storage'
export default {
  name: 'HelloWorld',
  data() {
    return {
      currentPage: 1, // 当前页码
      artList: [],
    }
  },
  methods: {
    currentChange(val) {
      this.currentPage = val
      this.getNewsList()
    },
    // 请求文章列表
    async getNewsList() {
      let cateName = this.$route.name
      get('/article/lists', { cateName: cateName })
        .then((res) => {
          this.artList = res.data.artList
          this.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 跳转文章详情页
    toNewsDetial(artId) {
      this.$router.push({ path: `/newsDetial/${artId}` })
    },
  },
  mounted() {
    this.getNewsList()
    let siteConfig = getItem('centerConfig')
    document.title = '活动中心 - ' + siteConfig.centerName
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  text-decoration: none;
  margin: 0px;
  padding: 0px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

// 主体部分
.topImg > img {
  width: 100%;
  display: block; //解决图片下1像素bug
}
.activityCenter-box {
  background: #eff2fa;
  padding-bottom: 100px;
  min-height: 500px;
}
.activityCenter {
  width: 1080px;
  // height: 2870px;
  background: #ffffff;
  margin: 0 auto;
  // padding-top: 60px;
  padding-top: 44px;
}
.activityCenter-list {
  width: 920px;
  height: 212px;
  background: #ffffff;
  border-radius: 12px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  cursor: pointer;
}
.no-content {
  text-align: center;
  padding-bottom: 40px;
}
// .activityCenter-list:last-child{
//   margin-bottom: 116px;
// }
.hoverActivityCenterList:hover {
  width: 920px;
  height: 212px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 12px;
}
.activityCenter-listInfo {
  width: 888px;
  height: 180px;
  border-radius: 12px;
  display: flex;
}
.activityCenter-left-img > img {
  width: 320px;
  height: 180px;
  border-radius: 12px;
}

.activityCenter-right-text {
  width: 536px;
  // vertical-align: middle;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.activityCenter-right-text div {
  width: 100%;
}

.activityCenter-list-title {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
}
.activityCenter-list-text {
  font-size: 14px;
  color: #9b9da2;
  padding-top: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: initial;
}
.hover-item:hover .hover-item-title {
  color: #4676f8;
}
.activityCenter-time {
  font-size: 12px;
  font-weight: 400;
  color: #9b9da2;
  margin-top: 16px;
}
// 分页样式
.el-pagination {
  padding-left: 70%;
  padding-bottom: 20px;
}
</style>
