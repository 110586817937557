import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeLayout from '../layout/HomeLayout.vue'
import HomeIndex from '../views/HomeIndex.vue'
import central from '../views/central.vue'
import mind from '../views/mind.vue'
import fun from '../views/fun.vue'
import course from '../views/course.vue'
import paper from '../views/paper.vue'
import notice from '../views/notice.vue'
import cases from '../views/cases.vue'
import activityCenter from '../views/activityCenter.vue'
import indexNom from '../views/index-nom.vue'
import newsDetial from '../views/index-news.vue'
import testingDeail from '../views/testingDeail.vue'
import err404 from '@/views/404.vue'

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'home',
        component: HomeLayout,
        redirect: '/index', //路由重定向
        children: [
            {
                path: 'index',
                name: 'index',
                component: HomeIndex,
            }, {
                path: 'center',
                name: 'center',
                component: central,
                children: []
            },
            {
                path: 'psyhelp',
                name: 'psyhelp',
                component: mind
            },
            {
                path: 'testing',
                name: 'testing',
                component: fun
            },
            {
                path: 'course',
                name: 'course',
                component: course,
            },
            {
                path: 'psyprose',
                name: 'psyprose',
                component: paper
            },
            {
                path: 'news',
                name: 'news',
                component: notice,
            },
            {
                path: 'cases',
                name: 'cases',
                component: cases,
            },
            {
                path: 'acenter',
                name: 'acenter',
                component: activityCenter
            },
            {
                path: 'indexNom',
                name: 'indexNom',
                component: indexNom
            },
            {
                path: 'newsDetial/:artId',
                name: 'newsDetial',
                component: newsDetial
            },
            {
                path: 'testingDeail/:artId',
                name: 'testingDeail',
                component: testingDeail
            },

        ],

    },
    {
        path: '/404',
        name: 'err',
        component: err404
    },
    {
        path: '*',
        redirect: '/404'
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router