var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"center-box"},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"center-nav"},[_c('div',{class:{
            'center-nav-list': 'center-nav-list',
            'center-nav-list-active': _vm.tabsActive == 'centerinfo',
          },on:{"click":function($event){_vm.tabsActive = 'centerinfo'}}},[_vm._v(" 中心概况 ")]),_c('div',{class:{
            'center-nav-list': 'center-nav-list',
            'center-nav-list-active': _vm.tabsActive == 'teachers',
          },on:{"click":function($event){_vm.tabsActive = 'teachers'}}},[_vm._v(" 师资团队 ")]),_c('div',{class:{
            'center-nav-list': 'center-nav-list',
            'center-nav-list-active': _vm.tabsActive == 'schedule',
          },on:{"click":function($event){_vm.tabsActive = 'schedule'}}},[_vm._v(" 值班表 ")])]),_c('div',[(_vm.tabsActive == 'centerinfo')?_c('div',{staticClass:"centent1"},[_c('div',{staticClass:"article-box"},[_c('div',{staticClass:"article-text",domProps:{"innerHTML":_vm._s(_vm.centerInfo.pageBody)}})])]):_vm._e(),(_vm.tabsActive == 'teachers')?_c('div',{staticClass:"centent2"},[_c('div',{staticClass:"article-box"},[_vm._l((_vm.teacherTeam),function(teacherTeamItem){return _c('div',{key:teacherTeamItem.id,staticClass:"teacherTeam-box"},[_c('div',{staticClass:"teacherTeam-img"},[(teacherTeamItem.teacherPhoto == '')?_c('div',[_c('img',{attrs:{"src":require("../assets/home/dafaultHead.png"),"alt":""}})]):_c('div',[_c('img',{attrs:{"src":teacherTeamItem.teacherPhoto}})])]),_c('div',{staticClass:"teacherTeam-text"},[_c('div',[_c('div',{staticClass:"teacherTeam-teacher"},[_c('span',{staticClass:"teacherName"},[_vm._v(_vm._s(teacherTeamItem.teacherName))]),_c('span',{staticClass:"teacherIntro"},[_vm._v(_vm._s(teacherTeamItem.teacherTitle))])]),_c('div',{staticClass:"teacherTeam-text-info"},[_vm._v(" "+_vm._s(teacherTeamItem.introduce)+" ")]),(teacherTeamItem.introduce == '')?_c('div',{staticClass:"teacherTeam-text-info"},[_vm._v("暂无介绍")]):_vm._e()])])])}),(_vm.teacherTeam.length)?_c('el-pagination',{attrs:{"page-size":_vm.pageSize,"layout":"prev, pager, next","total":_vm.teacherTeamCopy.length,"pager-count:7":"","current-page":_vm.currentPage},on:{"current-change":_vm.currentChange}}):_vm._e(),(!_vm.teacherTeam.length)?_c('div',{staticClass:"no-content"},[_c('img',{attrs:{"src":require("../assets/central/noContent.png"),"alt":""}})]):_vm._e()],2)]):_vm._e()]),(_vm.tabsActive == 'schedule')?_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-text table-form",domProps:{"innerHTML":_vm._s(_vm.schedule.pageBody)}}),_c('div',{staticClass:"table-form"})]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":require("../assets/central/img-top.png"),"alt":""}})])}]

export { render, staticRenderFns }